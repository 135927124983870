const $ = window.$;
export default class WizardComponent {
    constructor(id, preventStepsClick) {
        this.id = id;
        this.$container = $('#' + this.id)
        this.currentStep = 1;
        this.stepsSelector = ".step"
        this.panelsSelector = ".warranty-activation-tab"
        this.form = this.$container.tagName === "FORM" ? this.$container : this.$container.closest('FORM')
        this.disableStepsClick = preventStepsClick ? preventStepsClick : true
        this.init();
    }

    init() {
        if (this.form.find('[name="currentStep"]').length > 0) {
            this.currentStep = this.form.find('[name="currentStep"]').val();
        }
        this.changeStep(this.currentStep);
        let that = this;
        this.$container.on('click', this.stepsSelector, function (event) {
            event.preventDefault();
            if (that.disableStepsClick === false) {
                let clickedStep = $(this);
                let stepIndex = clickedStep.index() + 1;
                that.changeStep(stepIndex);
            }
        });
    };

    changeStep(index) {
        if (index > 0 && index < this.$container.find(this.stepsSelector).length+1) {
            this.currentStep = index;
            this.form.find('[name="currentStep"]').val(index);
            this.$container.find(this.stepsSelector).removeClass('active');
            this.$container.find(this.panelsSelector).removeClass('active');
            this.$container.find(this.stepsSelector + ':nth-child(' + this.currentStep + ')').addClass('active')
            this.$container.find(this.panelsSelector + ':nth-child(' + this.currentStep + ')').addClass('active')
        }
    }

    nextStep() {
        this.changeStep(parseInt(this.currentStep) + 1)
    }

    previousStep() {
        this.changeStep(parseInt(this.currentStep) - 1)
    }
}