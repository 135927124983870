import WizardComponent from "./WizardComponent";
import {Dropzone} from "dropzone";
import {DatePicker} from "jquery-ui/ui/i18n/datepicker-fr"

const $ = window.$;
export default class WarrantyActivationComponent {
    constructor() {
    }

    init() {
        const that = this;
        const wizard = new WizardComponent('after-sales-service-form-wizard', true);
        $('.warranty-activation-tab.step-1 .warranty-activation-form-next-btn').on('click', function (event) {
            event.preventDefault();
            if (true === that.checkReference()) {
                wizard.nextStep();
            }
        });
        this.initSAVDatePicker();
        $('.warranty-activation-tab.step-2 .warranty-activation-form-next-btn').on('click', function (event) {
            event.preventDefault()
            const btn = $('.warranty-activation-tab.step-2 .warranty-activation-form-next-btn')
            btn.attr('disabled', true);
            const tab = $('.warranty-activation-tab.step-2')
            let valid = that.validateForm(tab);
            btn.removeAttr('disabled');
            if (true === valid) {
                $('.col-3.step-2.step.active').addClass('finish');
                wizard.nextStep();
            } else {
                $('.col-3.step-2.step.active').removeClass('finish');
            }
        });

        $('.warranty-activation-tab.step-3 .warranty-activation-form-next-btn').on('click', function (event) {
            event.preventDefault()
            const btn = $('.warranty-activation-tab.step-3 .warranty-activation-form-next-btn')
            btn.attr('disabled', true);
            const tab = $('.warranty-activation-tab.step-3')
            $('#file_path').attr('required', true);
            let valid = that.validateForm(tab);
            let submit = false;
            if (true === valid) {
                submit = that.saveWarrantyActivations(wizard);
            } else {
                btn.removeAttr('disabled');
            }
        });
        this.initSAVDropzone();
        this.initProductList();
    }

    initSAVDatePicker() {
        if ($('.after-sales-service-form-wizard .input-date').length > 0) {
            $('.after-sales-service-form-wizard .input-date').datepicker({
                maxDate: "+0D",
                dateFormat: 'dd/mm/yy',
                altField: "#date_purchase",
                altFormat: "yy-mm-dd",
            });
        }
    }

    initSAVDropzone() {
        if ($("#warrany-activation-invoice-file").length > 0) {
            $("#warrany-activation-invoice-file").attr('data-url')
            $("#warrany-activation-invoice-file").dropzone({
                autoProcessQueue: true,
                maxFilesize: 20,
                maxFiles: 1,
                paramName: 'uploadedFile',
                url: $("#warrany-activation-invoice-file").attr('data-url'),
                clickable: true,
                acceptedFiles: "image/jpg,image/jpeg,image/png,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,video/mp4,video/avi,video/wmv,video/webm",
                uploadMultiple: false,
                addRemoveLinks: true,
                params: function (files, xhr, chunk) {
                    return {
                        ref: $('#ref-extern-product').val()
                    }
                },
                init: function () {
                    let filepath = $('#file_path').val();
                    this.on("success", function (file, response) {
                        var result = jQuery.parseJSON(response);
                        if (typeof result.error != 'undefined') {
                            $(file.previewElement).addClass("dz-error").find('.dz-error-message').text(result.error);
                        }
                        if (result.success == true) {
                            filepath = result.filename;
                            $('#file_path').val(filepath);
                            $('#file_path').removeClass('invalid');
                        }else{
                            $('#file_path').val('');
                            $('#file_path').addClass('invalid');
                        }
                    });
                    this.on("maxfilesexceeded", function (file) {
                        this.removeFile(file);
                    });
                    this.on('error', function (file, response) {
                        $(file.previewElement).addClass("dz-error").find('.dz-error-message').text(response);
                        if ($('body .after-sales-service-popup').length == 0) {
                            $('body').append(
                                '<div class="after-sales-service-popup">' +
                                '<div class="after-sales-service-ppu-b">' +
                                '<button class="close close-btn"><i class="fas fa-times-circle"></i></button>' +
                                '<i class="fas fa-times-circle error-icon"></i>' +
                                '<span>' + response + '</span>' +
                                '<button class="return-btn close-btn">' + AfterSalesServiceMessages['Back'] + '</button>' +
                                '</div>' +
                                '</div>'
                            );
                        }
                        this.removeFile(file);
                    });
                },
                error: function (file, message) {
                    console.log(message);
                },
                dictDefaultMessage: AfterSalesServiceMessages['DropZoneTextDefaultMessage'],
                dictFallbackMessage: AfterSalesServiceMessages['DropZoneTextFallbackMessage'],
                dictFallbackText: AfterSalesServiceMessages['DropZoneTextFallbackText'],
                dictFileTooBig: AfterSalesServiceMessages['DropZoneTextFileTooBig'],
                dictInvalidFileType: AfterSalesServiceMessages['DropZoneTextInvalidFileType'],
                dictResponseError: AfterSalesServiceMessages['DropZoneTextResponseError'],
                dictCancelUpload: AfterSalesServiceMessages['DropZoneTextCancelUpload'],
                dictCancelUploadConfirmation: AfterSalesServiceMessages['DropZoneTextCancelUploadConfirmation'],
                dictRemoveFile: AfterSalesServiceMessages['DropZoneTextRemoveFile'],
                dictMaxFilesExceeded: AfterSalesServiceMessages['DropZoneTextMaxFilesExceeded']

            });
        }
    }

    saveWarrantyActivations(wizard) {
        const btn = $('.warranty-activation-tab.step-3 .warranty-activation-form-next-btn')
        const form = btn.closest('FORM');

        let data = form.serializeArray();
        data = $.extend(data, {'ajax': true});
        const url = form.attr('data-action');
        $.ajax({
            url: url,
            type: 'POST',
            cache: false,
            dataType: 'json',
            async: false,
            data: data,
            success: function (result) {
                if (result.success === true) {
                    $('.col-3.step-4.step.active').removeClass('active');
                } else {
                    $('.col-3.step-4.step.active').removeClass('finish');
                }
                if (result.message) {
                    $('.warranty-activation-tab.step-4 #message').html(result.message)
                }
                if (result.warning === true ) {
                    $('#egcegidaftersaleservice_ko').removeClass('d-none');
                    $('#egcegidaftersaleservice_ok').hide();
                }
                if (result.link) {
                    $('.warranty-activation-tab.step-4 #finalLink').attr('href', result.link)
                }
            },
            complete: function () {
                btn.removeAttr('disabled');
                $('.col-3.step-3.step.active').addClass('finish');
                wizard.nextStep();
                $('.col-3.step-4.step.active').addClass('finish');
            }
        });
    }

    validateForm(tab) {
        const inputs = tab.find(':input');
        $.each(inputs, function (index, input) {
            const $input = $(input);
            if ($input.attr('type') === 'button')
                return;
            if ($input.attr('type') === 'submit')
                return;
            if ($input.attr('type') === 'reset') {
                return;
            }
            if ($input.attr('required') && $input.val() === "") {
                $input.addClass('invalid')
            }
        })
        if (tab.find(':input.invalid').length > 0) {
            return false;
        }
        return true;
    }

    checkReference() {
        const btn = $('.warranty-activation-tab.step-1 .warranty-activation-form-next-btn')
        const ref = $('#ref-extern-product').val();
        $('#ref-extern-product').addClass('invalid');
        $.ajax({
            type: 'POST',
            cache: false,
            async: false,
            dataType: 'json',
            data: {
                ajax: true,
                'ref': ref,
                action: 'refExists',
            },
            beforeSend: function () {
                console.log(btn);
                btn.html(AfterSalesServiceMessages['Processing...']);
                btn.attr('disabled', true);
            },
            success: function (result) {
                if (result.success = true) {
                    $('.show-error').text('');
                    btn.html(AfterSalesServiceMessages['Next']);
                    if (result.exist == false) {
                        $('#ref-extern-product').addClass('invalid');
                        $('.left-side label strong').remove();
                        $('.left-side label').append('<strong class="text-danger">' + AfterSalesServiceMessages['Not found product reference'] + '</strong>')
                    } else {
                        $('#ref-extern-product').removeClass('invalid');
                    }
                } else {
                    console.log(result)
                }
            }
        });
        btn.removeAttr('disabled');
        if ($('#ref-extern-product').hasClass('invalid')) {
            $('.col-3.step-1.step.active').removeClass('finish');
            return false;
        }
        $('.col-3.step-1.step.active').addClass('finish');
        return true;
    }

    initProductList() {
        const that = this;
        let listSelector = '.warranty-activation-products';
        let productSelector = '.product-block';
        let checkboxInput = '.checkbox input';
        const url = $(listSelector).closest('FORM').attr('data-action');
        const reloadUrl = $(listSelector).closest('FORM').attr('data-reload');
        $(document).on('click', listSelector + ' ' + productSelector + ' ' + checkboxInput, function (event) {
                //  event.preventDefault();
                let checkbox = $(this)
                if (checkbox.attr('disabled') || checkbox.closest(productSelector).hasClass('selected')) {
                    return;
                } else {
                    let productElement = checkbox.closest(productSelector)
                    productElement.addClass('selected');
                    console.log(productElement)
                    let data = {
                        id_product: productElement.attr('data-product'),
                        id_order: productElement.attr('data-order'),
                        ajax: true,
                        type: $('input[name="type"]').val()
                    }
                    $(listSelector).html('');
                    $('#loader').removeClass('d-none');
                    $.ajax({
                        url: url,
                        type: 'POST',
                        cache: false,
                        dataType: 'json',
                        async: false,
                        data: data,
                        success: function (result) {
                            $('#alert').removeClass('alert-success');
                            $('#alert').removeClass('alert-danger');
                            if (result.success === true) {
                                $('#alert').addClass('alert-success');
                            } else {
                                $('#alert').addClass('alert-danger');
                            }
                            if (result.message) {
                                $('#alert').removeClass('d-none')
                                $('#alert').html(result.message)
                            }
                        },
                        complete: function () {
                            $('#loader').addClass('d-none');
                            setTimeout(function () {
                                $('#alert').addClass('d-none');
                            }, 120000);
                            $.ajax({
                                url: reloadUrl,
                                type: 'GET',
                                async: false,
                                dataType: 'json',
                                data: {
                                    ajax: true,
                                },
                                success: function (result) {
                                    $(listSelector).html(result.content);
                                    that.initProductList();
                                }
                            });
                        }
                    });
                }
            }
        );
    }
}